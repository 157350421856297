@media only screen and (min-width: 480px) {
    /* styles for devices with a minimum screen width of 480px */
    .App {
        text-align: center;
    }
    
    .data-area-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: left;
    }
    
    .iconText {
        margin: 0 .5rem
    }
    
    .navbarStyle {
        max-height: 5em;
        background-color: rgb(235,228,227);
    }
    
    .navbarLogo {
        max-height: 5em;
    }
    
    .navbarCenter {
        text-align: center;
    }
    
    .navbarButton {
        color: #fff !important;
        padding: .5rem 1rem !important;
    }
    
    .tableColumn {
        word-break: break-all
    }
    
    .table {
        max-height: 37rem;
    }
    
    .card {
        max-width: 20rem !important;
        margin: 2px;
        padding: 2px;
    }
    
    .cardImage {
        width: 180px !important;
        height: 180px;
    
    }
    
    .warningMessage {
        color: red;
    }
}
    
@media only screen and (max-width: 480px) {
    /* styles for devices with a maximum screen width of 480px */
    .App {
        text-align: center;
    }
    
    .data-area-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: left;
    }
    
    .iconText {
        margin: 0 .5rem
    }
    
    .navbarStyle {
        max-height: 5em;
        background-color: rgb(235,228,227);
    }
    
    .navbarLogo {
        max-height: 5em;
    }
    
    .navbarCenter {
        text-align: center;
    }
    
    .navbarButton {
        color: #fff !important;
        padding: .5rem 1rem !important;
    }
    
    .tableColumn {
        word-break: break-all
    }
    
    .table {
        max-height: 37rem;
    }
    
    .card {
        max-width: 20rem !important;
        margin: 2px;
        padding: 2px;
    }
    
    .cardImage {
        width: 180px !important;
        height: 180px;
    
    }
    
    .warningMessage {
        color: red;
    }
}